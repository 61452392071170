@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&family=PT+Serif:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sectionSize {
    @apply lg:px-48 md:px-12 px-4 py-14 flex flex-col items-center;
  }

  .secondaryTitle {
    @apply font-pt-serif text-3xl bg-left-bottom bg-no-repeat pb-8 px-16 mb-8;
  }
}

.growing-underline {
  display: block;
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;
}

.growing-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: black;
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.growing-underline:hover::after,
.growing-underline:focus::after {
  transform: translate3d(0, 0, 0);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}
